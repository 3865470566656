<template>
    <el-table
        id="printMe"
        ref="table"
        border
        stripe
        style="width: 100%"
        :data="tableData"
        size="mini"
        :highlight-current-row="true"
        max-height="562"
    >
        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
        <el-table-column label="客户端编码" width="200" prop="code" />
        <el-table-column label="所属机构组" min-width="240" prop="deptGroupName" />
        <el-table-column label="注册机构" min-width="240" prop="deptName" sortable :sort-method="sortDeptName" />
        <el-table-column label="注册码" width="260" prop="regCode" />
        <el-table-column label="注册时间" width="150" prop="regDate" sortable :sort-method="sortRegDate" />
        <el-table-column label="CPU序列号" width="130" prop="cpuId" sortable :sort-method="sortCPU" />
        <el-table-column label="在线状态" width="100" prop="status">
            <template slot-scope="scope">
                <span v-if="scope.row.status === '1'">在线</span>
            </template>
        </el-table-column>
        <el-table-column label="使用状态" width="130" prop="valid" key="valid">
            <template slot-scope="scope">
                <span v-if="!scope.row.deptCode">未使用</span>
                <span class="clr-green" v-else-if="scope.row.valid">使用中</span>
                <span class="clr-pink" v-else>已作废</span>
            </template>
        </el-table-column>
        <el-table-column
            label="类型"
            width="120"
            prop="clientType"
            key="clientType"
            sortable
            :sort-method="sortClientType"
        >
            <template slot-scope="scope">
                <span>{{ scope.row.clientType | clientType }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="80" header-align="center" prop="operate" key="operate" fixed="right">
            <template slot-scope="scope">
                <el-button
                    v-if="scope.row.deptCode && scope.row.valid && hasPrivilege('menu.system.regCode.edit')"
                    size="mini"
                    type="text"
                    class="clr-pink"
                    @click="handleDisabled(scope.row)"
                    >作废
                </el-button>
                <el-button
                    v-if="scope.row.deptCode && !scope.row.valid && hasPrivilege('menu.system.regCode.edit')"
                    size="mini"
                    type="text"
                    class="clr-green"
                    @click="handleReset(scope.row)"
                    >恢复
                </el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import Util from 'js/Util';

export default {
    name: 'RegCodeTable',
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        handleReset(row) {
            this.$emit('enable', row.code);
        },
        handleDisabled(row) {
            this.$emit('disable', row.code);
        },
        sort(a, b) {
            if (Util.isEmpty(a)) {
                return -1;
            } else if (Util.isEmpty(b)) {
                return 1;
            } else {
                return String(a).localeCompare(String(b), 'zh');
            }
        },
        sortRegDate(a, b) {
            return this.sort(a.regDate, b.regDate);
        },
        sortDeptName(a, b) {
            return this.sort(a.deptName, b.deptName);
        },
        sortClientType(a, b) {
            return this.sort(a.clientType, b.clientType);
        },
        sortCPU(a, b) {
            return this.sort(a.cpuId, b.cpuId);
        },
    },
    filters: {
        clientType(clientType) {
            if (typeof clientType == 'undefined') {
                return '';
            }
            if (clientType == '0') {
                return 'Win7/Linux x64';
            }
            if (clientType == '1') {
                return 'Win7 x32';
            }
            if (clientType == '2') {
                return 'XP';
            }
            if (clientType == '3') {
                return 'ANDROID';
            }
            if (clientType == '4') {
                return 'AND APP';
            }
            if (clientType == '5') {
                return 'IOS APP';
            }
        },
        valid(valid) {
            if (typeof valid == 'undefined') {
                return '';
            }
            if (valid) {
                return '';
            }
            if (valid == false) {
                return '作废';
            }
        },
    },
};
</script>
